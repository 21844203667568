import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { appURLs } from "@/app/app-urls.mjs";
import {
  statCardsCss,
  TopStatCardsFallback,
} from "@/game-destiny2/components/Profile/TopStatCards.jsx";
import type { WeaponStats } from "@/game-destiny2/models/destiny2-player-weapon-stats.mjs";
import type { Weapon } from "@/game-destiny2/models/model-wiki.mjs";
import HighlightStatCard from "@/shared-fps/HighlightStatCard.jsx";
import TimescaleChart from "@/shared-fps/TimescaleChart.jsx";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { dataIsLoaded } from "@/util/wiki.mjs";

export default memo(function WeaponTopStatCards({
  weaponStats,
  weapons,
}: {
  weaponStats: WeaponStats[];
  weapons: Record<number, Weapon>;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  if (!weaponStats || !dataIsLoaded(weapons)) return <TopStatCardsFallback />;

  const stats = weaponStats
    .filter((w) => w.count)
    .sort((aStat, bStat) => bStat.kills - aStat.kills);
  if (!stats.length) return null;

  const mostKills = stats[0];
  const mostKillsWeapon = weapons[mostKills.weaponReferenceId];
  const highlight = {
    label: mostKillsWeapon?.label,
    position: 1,
    stat: mostKills.kills.toLocaleString(language, {
      maximumFractionDigits: 0,
    }),
    statLabel: t("common:stats.kills", "Kills"),
    image: mostKillsWeapon && `${appURLs.BUNGIE}${mostKillsWeapon.iconUri}`,
    imageSize: 100,
  };

  const timeScaleData = stats.slice(0, 20).map((i) => {
    const totalKills = i.kills;
    const precisionKillAvg = sanitizeNumber(i.precisionKills / i.count);
    const killAvg = sanitizeNumber(totalKills / i.count);
    const weapon = weapons[i.weaponReferenceId];
    return {
      key: `${i.weaponReferenceId}`,
      tooltipTitle: (
        <div>{weapon?.label ?? t("destiny2:unknownItem", "Unknown Item")}</div>
      ),
      stats: {
        kills: {
          value: totalKills,
          display: totalKills.toLocaleString(language),
        },
        precisionKills: {
          value: i.precisionKills,
          display: i.precisionKills.toLocaleString(language),
        },
        totalKills: {
          value: i.kills,
          display: i.kills.toLocaleString(language),
        },
        killAvg: {
          value: killAvg,
          display: killAvg.toLocaleString(language),
        },
        precisionKillAvg: {
          value: precisionKillAvg,
          display: precisionKillAvg.toLocaleString(language),
        },
        count: {
          value: i.count,
          display: i.count.toLocaleString(language),
        },
      },
    };
  });
  const avgs = {
    id: "avgs",
    label: ["val:avgKills", "Avg. Kills"] as Translation,
    position: 0,
    stat1Label: ["val:avgKills", "Avg. Kills"] as Translation,
    stat2Label: [
      "destiny2:stats.avgPrecisionKills",
      "Avg. Precision Kills",
    ] as Translation,
    color: "var(--red)",
    data: timeScaleData,
    xField: "totalKills",
    yField: "killAvg",
    y2Field: "precisionKillAvg",
    hidePosition: true,
  };
  const kills = {
    id: "kills",
    label: ["common:stats.kills", "Kills"] as Translation,
    position: 0,
    stat1Label: ["common:stats.kills", "Kills"] as Translation,
    stat2Label: ["common:precisionKills", "Precision Kills"] as Translation,
    color: "var(--orange)",
    data: timeScaleData,
    xField: "totalKills",
    yField: "kills",
    y2Field: "precisionKills",
    hidePosition: true,
  };
  return (
    <div className={statCardsCss()}>
      {React.createElement(HighlightStatCard, highlight)}
      {React.createElement(TimescaleChart, kills)}
      {React.createElement(TimescaleChart, avgs)}
    </div>
  );
});
